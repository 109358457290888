.amin-login-fail {
    animation: login-fail 0.3s;
    position: relative;
}

@keyframes login-fail {
    25% {
        left: 20px;
    } 

    50% {
        left: 0px;
        right: 20px;
    }

    75% {
        right: 0px;
        left: 20px;
    }

    to {
        left: 0px;
        right: 20px;
    }
}

.login_fields {
    & label {
        color: white !important;
        opacity: 0.7;

        & + div {
            &:before {
                border-color: rgb(228, 228, 228) !important;
            }

            &:before:hover {
                border-color: grey !important;
            }

            &:after {
                border-color: white !important;
            }
        }
    }

    & input {
        color: #ffffffdb !important;
    }
}

.login_logo {
    width: 240px;
}

// google verification

.googleVerification {
    margin-top: 42px;
    &__verifyBtn {
        margin:  0 auto;
        margin-top: 32px;
        width: fit-content;
    }
}

.loginPage__text {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 13px;
    font-weight: 600;
}

.loginPage__text--error {
    @extend .loginPage__text;
    text-align: center;
    color: rgb(179, 28, 28);
    margin-top: 20px;
    animation: fadeIn 0.5s;
    background: rgba(241, 241, 241, 0.432);
    border-radius: 2px;
}

.verificationSuccess {
    margin-top:  40px;

    &__text {
        font-size: 15px;
        color: #C2D3E7;
        text-align: center;
        margin-top: 20px;
    }

    &__successImgWrapper {
        margin:  0 auto;
        margin-top: 20px;
        width: fit-content;
        margin-bottom: 20px;
    }

    &__successImg {
        width: 100%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.menu__googleBtn {
    border-radius: 8px;
    background: #fff;
    margin-top: 20px;       
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    transition: .2s ease-in-out;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    img {
       margin-right: 10px;
       width: 20px;
    }
}