.number_item {
    max-width: 350px;
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
    margin: 10px 30px 10px;

    & > .actions {
        position: absolute;
        top: -35px;
        right: -50px;

        & > .button_action {
            margin: 0 2.5px;

            &.-edit {
                background-color: #1460AA;
                color: white;

                & > span > span {
                    font-size: 19px;
                }
            }

            &.-remove {
                position: relative;
                top: 10px;
            }
        }
    }

    & > .content {
        overflow: hidden;
        border-radius: 5px;

        & > .head {
            text-align: center;
            background: #1460AA;
            padding: 15px 0;
            color: white;
            font-weight: 600;
        }

        & > .text {
            padding: 10px;
        }

        & > .phone {
            padding: 10px 0;
            text-align: center;
            background: #1460AA;
            color: white;
            font-weight: 600;

            // &:hover {
            //     cursor: pointer;
            //     opacity: 0.8;
            // }
        }
    }
}