.message_section {
    position: relative;
    color: white;
    font-weight: 100;
    margin: 30px 30px 30px 70px;
    width: fit-content;


    .message {
        width: 352px !important;
        text-align: center;
        box-sizing: border-box;
        padding: 20px 50px !important;
        font-weight: 600 !important;
        border-radius: 47px !important;
        background: #ecf1f9;
        color: #417BC3;
        width: fit-content;
        border: 1px solid transparent;
        cursor: pointer;

        &.admin:hover {
            cursor: pointer;
        }

        & * {
            color: #171717;
        }

        & a {
            text-decoration: underline;
        }

        & p {
            padding: 0;
            margin: 0;
        }
    }

    .message--active {
        @extend .message;
        border: 1px solid #CFDCED;
    }


    .orderButtons {
        position: absolute;
        left: -35px;
        top: 50%;
        transform: translateY(-50%);
    }

    .actionAbuttons {
        position: absolute;
        top: -20px;
        right: 0px; 
        display: flex;
    }

    .orderArrowWrapper {
        padding: 4px 7px;
        cursor: pointer;

        &:hover .triangle-down{
            border-top: 10px solid #b1c7e6;
        }

        &:hover .triangle-top{
            border-bottom: 10px solid #b1c7e6;
        }
    }

    .orderArrows {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
    }

    .triangle-down {
        @extend .orderArrows;
        border-top: 10px solid #C3D3E9;
    }

    .triangle-top {
        @extend .orderArrows;
        border-bottom: 10px solid #C3D3E9;
    }

    .messageTooltipWrapper {
        position: absolute;
        right: -570px;
        width: 600px;
        top: 0;
        opacity: 1;
        transition: .2s ease-in-out;
        z-index: 100;

        .messageTooltip {
            background: #CFDCED;
            border-radius: 16px;
            padding: 30px;
            margin-left: 50px;
            color: #000;
            position: relative;
    
            ::after {
                content: "";
                position: absolute;
                top: 30px;
                left: -10px;
                width: 0;
                height: 0;
                border-bottom: 9px solid transparent !important;
                border-top: 9px solid transparent !important;
                border-right: 10px solid #CFDCED;
            }
        }

    }

    .messageTooltipWrapper--bottom {
        position: absolute;
        right: 0;
        width: 600px;
        top: 85px;
        opacity: 1;
        transition: .2s ease-in-out;
        z-index: 100;

        .messageTooltip {
            background: #CFDCED;
            border-radius: 16px;
            padding: 30px;
            margin-left: 50px;
            color: #000;
            position: relative;
    
            ::after {
                content: "";
                position: absolute;
                top: -9px;
                right: 80px;
                width: 0;
                height: 0;
                border-right: 9px solid transparent !important;
                border-left: 9px solid transparent !important;
                border-bottom: 10px solid #CFDCED;
            }
        }
    }

    .messageTooltipWrapper--bottomRight {
        @extend .messageTooltipWrapper--bottom;
        // border: 2px solid green;
        left: 0;

        .messageTooltip {
            background: #CFDCED;
            border-radius: 16px;
            padding: 30px;
            margin-left: 0;
            color: #000;
            position: relative;
    
            ::after {
                content: "";
                position: absolute;
                top: -9px;
                left: 80px;
                width: 0;
                height: 0;
                border-right: 9px solid transparent !important;
                border-left: 9px solid transparent !important;
                border-bottom: 10px solid #CFDCED;
            }
        }
    }

}
