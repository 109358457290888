.menu {
    width: 220px;
    height: 100vh;
    min-height: 443px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.menu_logo {
    width: 65%;
    margin: 0 auto;
    display: block;
    margin-top: 45px;
    margin-bottom: 70px;
}

.menu_switcher {
    margin: 20px 20px 50px 20px;
    display: flex;
    grid-gap: 10px;
    padding: 5px 10px;
    border-radius: 29px;
    background: #588BCA;
    
    & .menu_switcherBtn {
        font-size: 14px;
        border-radius: 29px;
        flex: 1;
        padding: 8px 0;
        text-align: center;
        color: #fff;
        border: 1px solid transparent;
        cursor: pointer;
        transition: .2s ease-in-out;

        &:hover {
            background: #417bc3;
        }
    }

    & .menu_switcherBtn--active {
        @extend .menu_switcherBtn;
        pointer-events: none;
        background: linear-gradient(277.72deg, #FF9C29 28.61%, #E9A744 102.98%);

        // &:hover {
            // background: #f5a422;
        // }
    }
}

.menu_buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 20px;
    
    & .menu_button {
        width: 100%;
        padding: 10px 20px;
        background: none;
        border-radius: 0 !important;
        color: white !important;
        background: none !important;
        font-weight: 400;
        box-shadow: none !important;
        position: relative;
        margin-top: 5px;

        &:after {
            content: "";
            width: 95%;
            height: 100%;
            position: absolute;
            left: 0;
            background: #588BCA;
            z-index: -1;
            border-radius: 0 20px 20px 0;
            opacity: 0;
            transition: .2s ease-in-out;
        }

        &.-space{
            margin-bottom: 20px;
            position: relative;
        }
    
        &:hover::after {
            opacity: 1;
        }
    
        &.logout {
            // position: absolute;
            bottom: 10px;
            margin-top: 30px;
            padding-bottom: 40px;
        }
     }

     > .menu_button_active {
        width: 100%;
        padding: 10px 20px;
        background: none;
        border-radius: 0 !important;
        color: white !important;
        background: none !important;
        font-weight: 500;
        box-shadow: none !important;
        position: relative;
        margin-top: 5px;
        transition: .2s ease-in-out;

        &:after {
            content: "";
            width: 95%;
            height: 100%;
            position: absolute;
            left: 0;
            background: #588BCA;
            z-index: -1;
            border-radius: 0 20px 20px 0;
        }

        &.-space{
            margin-bottom: 20px;
        }
     }

     > a > .menu_button_active {
        width: 100%;
        padding: 10px 20px;
        background: none;
        border-radius: 0 !important;
        color: white !important;
        background: none !important;
        font-weight: 500;
        box-shadow: none !important;
        position: relative;
        margin-top: 5px;
        transition: .2s ease-in-out;

        &:after {
            content: "";
            width: 95%;
            height: 100%;
            position: absolute;
            left: 0;
            background: #588BCA;
            z-index: -1;
            border-radius: 0 20px 20px 0;
        }

        
     }

     > .saparate_button {
        //  display: flex;
     }
}

.menu_buttons_separator {
    height: 1px;
    background: #6F9BD1;
    width: 50%;
    margin:  0 auto;
    margin-top: 20px;
    margin-bottom: 20px
}

.space {
    // margin-bottom: 20px;
}


@media (max-height: 800px) {
    .menu_logo {
        margin-bottom: 20px;
    }
    .menu_buttons .menu_button.-space {
        margin-bottom: 20px;
    }

    .menu_buttons > .menu_button_active.-space {
        margin-bottom: 20px;
    }
}

@media (max-height: 700px) {
    .menu_buttons .menu_button {
        height: 35px;
    }

    .menu_buttons > .menu_button_active {
        height: 35px;
    }
}