.supplier_section {

    & > .content {
        display: flex;
        padding-top: 50px;
        flex-wrap: wrap;

        & > .supplier {
            position: relative;
            width: 250px;
            height: 150px;
            margin-left: 60px;
            margin-bottom: 60px;
            background: #1460AA69;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            // box-shadow: 0 0 23px 3px rgba(0,0,0,0.1);

            &:hover {
                // box-shadow: 0 3px 23px 3px rgba(0,0,0,0.1);
                // top: -5px;
                // left: -2px;

                cursor: default;
            }

            & > .content {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                
                & > .block_img {
                    width: 100%;
                    height: 100%;
                    
                    & > .image {
                        width: 100%;
                        height: 100%;
                    }
                }

                & > .block_text {
                    font-size: 24px;
                }
            }
        }
    }

}