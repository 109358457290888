.users_section {
    height: 45vh;
    display: flex;

    & > .add_new {
        min-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid rgba(0,0,0,0.1);

        & > .header {
            font-weight: 700;
            margin: 15px 0;
            color: #757575;
        }

        & > .fields {

            & > div {
                margin: 0 10px;
            }

            &.-pass {
                display: flex;
                width: 350px;

                & > div {
                    width: 100%;
                    margin: 0;
                }

                & > .action {
                    margin-top: 10px;

                    &.-generate {
                        max-width: 35px;
                        max-height: 35px;
                        width: 100%;
                        height: 100%;
                        margin-left: 10px;
                    }
                }
            }

            &.-role {
                width: 370px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;


                & > div {
                    width: 120px;
                }

                & > .superUser {
                    height: 20px;
                    margin-top: 20px;
                    margin-left: 0px;
                }
            }
        }

        & > .actions {
            margin-top: 10px;
        }
    }

    & > .list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        padding: 10px 0 30px 0;

        & > .user {
            margin-top: 20px;
            padding: 0 10px;
            width: 200px;
            display: flex;
            flex-direction: column;

            & > .name {
                align-self: flex-start;
                width: 100%;
                text-align: center;
                color: #636363;
            }

            & > .info {
                display: flex;
                margin: 10px 0;
                justify-content: space-around;
                width: 100%;
                font-size: 14px;
                font-weight: 700;
                color: rgb(95, 95, 95);
            }

            & > .actions {
                display: flex;
                justify-content: center;

                & > .action {
                    margin: 0 5px;
                    color: white;

                    &.-delete {
                        background: #ff5656;
                    }

                    &.-pass {
                        background-color: #3682ff;
                    }
                }
            }
        }
    }
}