.operation_section {
    margin-top: 30px; 

    & > .actions {
        position: fixed;
        right: 0;
        top: calc(35vh - 50px);
        height: 100px;
        width: 50px;
        border-radius: 15px 0 0 15px;
        box-shadow: 0 0 12px 1px rgba(0,0,0,0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        & > .action {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                opacity: 0.8;
            }

            &:active {
                box-shadow: inset 0 0 14px 1px rgba(0,0,0,0.15)
            }

            &.-edit {
                background-color: #ceddf1;
            }

            &.-remove {
                background-color: #f4d3d1;
            }
        }
    }


    & > .content {

        & > .supplier_list {

            & > .saparetor {
                display: inline-block;
                position: relative;
                color: #353535;
                font-weight: 900;
            }
    
            & > .content {
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                padding: 20px 0;
                
                & > ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
    
                    & > .supplier {
                        min-width: 190px;
                        width: 190px;
                        max-height: 114px;
                        background: #1460AA69;
                        margin: 0 20px;
                        padding: 0 !important;
                        display: flex;
                        position: relative;
                        justify-content: center;
                        align-items: center;
                        border-radius: 8px;
                        overflow: hidden;
    
                        &:hover {
                            cursor: pointer;
    
                            & > .actions {
                                display: flex;
                                
                            }
                        }
    
                        & > .actions {
                            position: absolute;
                            display: none;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
    
                            & > .background {
                                background: #caf8d5d2;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                opacity: 1;
                            }
    
                            & > .action {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
    
                        & > .content {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            & > .block_img {
                    
                                & > .image {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        
                            & > .text_block {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                            }
    
                            & > .selected {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: #caf8d5bd;
    
                                &::after {
                                    content: "Selected";
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                    color: #489b5c;
                                    font-size: 13px;
                                    padding: 5px 10px;
                                    background: #c1f7cd;
                                    border-radius: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        & > .main_section {

            .comperatore__switcher {
                border-radius: 30px;
                margin-top: 50px;
                margin-bottom: 50px;
            }

            .comperatore__switcherTab {
                padding: 10px 30px;
            }
            .comperatore__switcherTab--active {
                padding: 10px 30px;
            }

            & > .switch {
                display: flex;
                justify-content: center;
                margin-bottom: 50px;
                // max-width: 960px;
                margin-top: 40px;
                position: sticky;
                top: 20px;
                z-index: 1000;
        
                & > .button {
                    width: 150px;
                    padding: 8px 0;
                    background: #1460AA;
                    text-align: center;
                    color: white;
        
                    &.-left {
                        border-radius: 10px 0 0 10px;
                    }
        
                    &.-right {
                        border-radius: 0 10px 10px 0;
                    }
        
                    &.selected {
                        background: #f5A422;
                    }
        
                    &.no-selected:hover {
                        background: #f5A422;
                        cursor: pointer;
                    }
                }
            }
    
            & > .fields_section {
                flex-direction: column;
    
                & > .operations {
    
                    & > .saparetor {
                        display: inline-block;
                        position: relative;
                        color: #353535;
                        font-weight: 900;
                    }
        
                    & > .fields {
                        display: flex;
                        flex-wrap: wrap;
    
                        & > .content {
                            display: flex;
                            flex-wrap: wrap;
                            max-width: 980px;
                            margin-top: 10px;
    
                            & > div {
                                margin: 30px 0;
                            }
                        }
        
        
                        & > div {
                            margin-bottom: 30px;
        
                            &:last-of-type {
                                align-self: flex-start;
                            }
                        }
        
                        & label {
                            color: #1460AA !important;
                    
                            & + div {
                                &:before {
                                    border-color: #1460AA9c !important;
                                }
                    
                                &:before:hover {
                                    border-color: #1460AA86 !important;
                                }
                    
                                &:after {
                                    border-color: #1460AA !important;
                                }
                            }
                        }
                    
                        & input {
                            color: #1460AA !important;
                        }
            
                        & textarea {
                            color: #1460AA !important;
                            width: 350px;
                        }
                    }
                }
            }
        }

        & > .info_section {
            text-align: center;
            margin-top: 70px;
            color: grey;
        }
    }

    .offer_icon {
        color: white;
    }
}

    .action.-edit .offer_icon {
        color: #417cc3;
    }

    .action.-remove .offer_icon {
        color: #e15240;
    }
