.proposal_section {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 50vh;
    max-width: 1060px;
    margin-top: 50px;

    & > .section {

        & > .bonus_text {
            position: relative;
            display: inline-block;
            background: #f7f7f7;
            padding: 17px 16px;
            border-radius: 4px;
            margin-bottom: 20px;

            & > button {
                position: absolute;
                top: -15px;
                right: -15px;
                background: #D1DFF1;
                box-shadow: none;

                .offer_icon {
                    color: #417BC3;
                    font-size: 18px;
                }
            }
        }

        & > .fields {

            &.-buttons {
                margin-top: 20px;
                margin-bottom: 20px;

                & > button {
                    background-color: #598bca;
                    color: white;
                    margin-right: 40px;
                    position: relative;

                    & > span > a {
                        color: white;
                    }
                    
                    & > span > button {
                        position: absolute;
                        top: -20px;
                        right: -20px;
                        width: 36px !important;
                        height: 36px !important;

                        & > span > span {
                            font-size: 18px;
                        }
                    }
                }
            }

            & > .telco {
                display: flex;

                & > div {
                    width: 250px;
                    margin-right: 50px;
                }

                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }
            }

            & > .tooltip {
                display: flex;
                position: relative;

                & > div:first-of-type {
                    width: 250px;
                }
                
                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }

                & > div {
                    & div {
                        color: #1460AA !important;
                    }
                }

                & > div:last-of-type {
                    margin-left: 110px;
                    margin-top: 15px;
                    color: #868686;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    position: relative;

                    & > .edit_tooltip {
                        width: 36px !important;
                        height: 36px !important;
                        position: absolute;
                        top: -15px;
                        right: -15px;
                        background: #D1DFF1;
                        box-shadow: none;

                        .offer_icon {
                            color: #417BC3;
                            font-size: 18px;
                        }

                        & > span {

                            & > span {
                                font-size: 18px;
                            }
                        }
                    }
                }

            }

            &.-no_tooltip {
                & > div {
                    width: 250px;

                    &:last-of-type {
                        margin-left: 70px;
                    }
                }

                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }

                & > div {
                    & div {
                        color: #1460AA !important;
                    }
                }
            }

            &.-search {
                & > div {
                    width: 100%;
                }

                & > .loading_city {
                    padding: 20px 0;
                    color: #1460AA !important;

                }

                & > .ext_data {
                    margin: 20px 0;

                    & > .data {
                        margin: 5px 0;
                    }
                }
                
                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }

                & > div {
                    & div {
                        color: #1460AA !important;
                    }

                    & div.errorMessage {
                        color: #e80000 !important;
                        font-size: 12px;
                        width: fit-content;
                        position: absolute;
                        right: 0;
                        bottom: -25px;
                    }
                }

                & > div > .address-search {
                        width: 100%;
                    
                    & label {
                        color: #1460AA !important;
                
                        & + div {
                            &:before {
                                border-color: #1460AA9c !important;
                            }
                
                            &:before:hover {
                                border-color: #1460AA86 !important;
                            }
                
                            &:after {
                                border-color: #1460AA !important;
                            }
                        }
                    }
    
                    & > div {
                        & div {
                            color: #1460AA !important;
                        }
                    }
                }
            }

            &.-search > .autocomplete__wrapper > .autocomplete__input--error {

                position: relative;

                & label {
                    color: #e80000 !important;
            
                    & + div {
                        &:before {
                            border-color: #e80000 !important;
                        }
            
                        &:before:hover {
                            border-color: #e80000 !important;
                        }
            
                        &:after {
                            border-color: #e80000 !important;
                        }
                    }
                }
            }
        }

        &.-product {
            margin-top: 50px;

            & > .product {
                display: flex;
                justify-content: space-between;

                & > .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    min-height: 200px;

                    &:first-child {
                        margin-right: 30px;
                    }

                    & > .header {
                        width: 200px;
                        height: 40px;
                        background-color: #598bca;
                        text-align: center;
                        line-height: 40px;
                        color: white;
                        font-weight: 400;
                        margin-bottom: 20px;
                    }

                    & > div {
                        width: 400px;
                    }

                    & label {
                        color: #1460AA !important;
                
                        & + div {
                            &:before {
                                border-color: #1460AA9c !important;
                            }
                
                            &:before:hover {
                                border-color: #1460AA86 !important;
                            }
                
                            &:after {
                                border-color: #1460AA !important;
                            }
                        }
                    }
    
                    & > div {
                        & div {
                            color: #1460AA !important;
                        }
                    }

                    & > .tooltip {
                        width: 100%;
                        max-width: 400px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        & > div:first-of-type {
                            width: 400px;
                        }

                        & > .text {
                            margin-top: 25px;
                            color: #868686 !important;
                            text-align: center;
                            font-size: 18px;
                            position: relative;

                            & > .edit_tooltip {
                                position: absolute;
                                top: -15px;
                                right: -15px;
                                width: 36px !important;
                                height: 36px !important;
                                background: #D1DFF1;
                                box-shadow: none;
                                color: #417BC3;
        
                                & > span {
        
                                    & > span {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }

                & > .line {
                    width: 1.5px;
                    height: 100px;
                    background-color: #1460AA;
                    align-self: center;
                }
            }
        }

        &.-info {

            & > .fields {

                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }

                & > div {
                    & div {
                        color: #1460AA !important;
                    }
                }

                & > div {
                    width: 250px;
                    margin-right: 70px;
                }

                & > .tooltip {
                    display: flex;
                    position: relative;
                    width: 100%;

                    & > .text {
                        color:#868686 !important;
                    }
    
                    & > div:first-of-type {
                        width: 250px;
                    }
    
                    & > div:last-of-type {
                        margin-left: 140px;
                        margin-top: 15px;
                        color: #868686;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        position: relative;

                        & > .edit_tooltip {
                            position: absolute;
                            right: -15px;
                            top: -15px;
                            // background: #D1DFF1;
                            // box-shadow: none;
                        }
                    }
    
                }
            }
        }
    }

    & > .offer {
        margin-top: 20px;

        & > .list_offer {
            display: flex;
            flex-direction: column; 
            // border-radius: 24px;
            // overflow: hidden;

            & > .head {
                display: flex;
                background: #F4F8FE;
                padding: 10px 20px;
                border-radius: 24px 24px 0 0;

                & > .field {
                    color: #111528B2;
                    height: 40px;
                    line-height: 40px;
                    // padding: 10px;
                    // padding-left: 5px;
                    // border-left: 1px solid rgba(0,0,0, 0.1);
                    // color: #8a8a8a;
                    // font-weight: 600;
                    font-size: 14px;
                    // box-sizing: border-box;

                    &.-name {
                        min-width: 250px;
                        max-width: 250px;
                    }

                    &.-valid {
                        min-width: 150px;
                        max-width: 150px;
                    }

                    &.-payment {
                        min-width: 200px;
                        max-width: 200px;
                    }

                    &.-supplier {
                        min-width: 150px;
                        max-width: 150px;
                    }

                }

            }

            & > .offer:last-child {
                border-radius: 0 0 24px 24px ;
            }

            & > .offer {
                position: relative;
                padding: 10px 20px;
                color: #000 !important;

                // padding: 10px 0 10px 20px;
                // color: #000;
                // border-top: 1px solid rgba(0,0,0, 0.1);
                // border-bottom: 1px solid rgba(0,0,0, 0.1);
                // border-right: 1px solid rgba(0,0,0, 0.1);\

                &.-not_valid {
                    background: rgba(253, 140, 140, 0.247) !important;

                    &:nth-child(odd) {
                        background: rgba(253, 140, 140, 0.144) !important;
                    }

                    // &:nth-child(even) {
                    //     background: rgba(253, 140, 140, 0.144) !important;
                    // }
                }

                &.-show_exp {
                    background: rgba(255, 238, 0, 0.1) !important;
                }

                &.-show_green {
                    background: #DCFDC8 !important;
                }

                &:nth-child(even) {
                    background: #fbfcff;
                }

                &:nth-child(odd) {
                    // background: #d7f6ff;
                    background: #F4F8FE;

                    & > .body {
                        background: #fbfcff;
                    }

                    &.-not_valid {
                        & > .body {
                            background: rgba(253, 140, 140, 0.247) !important;
                        }
                    }
                }

                & > .head {
                    display: flex;

                    &:hover {
                        cursor: pointer;
                        // opacity: 0.7;
                    }

                    & > .field {
                        height: 40px;
                        line-height: 40px;
                        // padding-left: 5px;
                        // border-left: 1px solid rgba(0,0,0, 0.1);
                        // color: grey;
                        color: #000;
                        font-weight: 400;
                        // font-weight: 700;
                        font-size: 14px;
    
                        &.-name {
                            min-width: 250px;
                            max-width: 250px;
                        }
    
                        &.-valid {
                            min-width: 150px;
                            max-width: 150px;
                        }
    
                        &.-payment {
                            min-width: 200px;
                            max-width: 200px;
                        }
    
                        &.-supplier {
                            min-width: 150px;
                            max-width: 210px;

                            & > .offer_supplier {
                                & > .sup_name {
                                    width: 200px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                & > .body {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid #efefef;
                    padding: 0 5px;
                    color: #000;
                    // font-weight: 700;

                    & > .section {
                        display: flex;
                        justify-content: space-around;
                        border-bottom: 1px solid #efefef;

                        &.-price_luce {
                            justify-content: center;
                            padding-left: 30px;
                            

                            & > .field {
                                padding: 5px 10px;
                                margin: 0px 10px;
                            }
                        }


                        & > .field {
                            height: 40px;
                            line-height: 40px;
                            font-weight: 500;

                            & > .text {
                                color: #797979;
                                font-weight: 600;
                                font-size: 14px;
                            }

                        }

                        & > .link_offer {
                            display: flex;
                            justify-content: center;
                            padding: 5px 0;
                            
                            & > a > button {
                                background: #1460AA;
                                color: white;
                            }
                        }

                        &.-script {
                            flex-direction: column;
                            border-bottom: none;

                            & > .field {
                                border-bottom: 1px solid #efefef;
                                height: auto;

                                & > .header {
                                    margin-top: 0;
                                    margin-bottom: 0px;
                                    text-align: center;
                                    color: #6e6e6e;
                                    font-size: 16px;
                                }

                                & > .text_script {
                                    padding: 10px 50px;
                                    color: grey;
                                    line-height: 23px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                & > .radio {
                    position: absolute;
                    top: -3px;
                    right: -50px;
                    color: #417BC3;
                }
            }
        }
    }

    & > .actions {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 30px;

        & > .button {
            background: #417BC3;
            margin: 0 10px;
            color: white;

            & > span > a {
                color: white;
            }
        }

    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.text_center {
    text-align: center;
}

.hide {
    display: none !important;
}


.offerExpandBtn {
    display: flex;
    align-items: center;
    margin-left: 70px;
}