.new_offer_seciton {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 30vh;


    & > .content {

            & > .saparetor {
                display: inline-block;
                position: relative;
                font-size: 18px;
                color: #353535;
                margin-top: 30px;
                font-weight: 900;
            }

        & > .filters {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            position: relative;
            width: calc(100% - 120px);
            margin-top: 20px;

            &.-m {
                top: -55px;
            }

            &.-not {
                width: calc(100% - 140px);
                top: -65px;
                left: 140px;
            }

            & > .item {
                padding: 10px 0;
                width: 170px;
                height: fit-content;
                font-weight: 600;
                text-align: center;
                color: #1460AA;
                border-bottom: 1px solid #1460AA;
                margin-bottom: 30px;
                margin-right: 30px;

                &.no_select {
                    &:hover {
                        cursor: pointer;
                        background: #1460AA0e;
                        height: fit-content;
                    }

                    &:active {
                        cursor: pointer;
                        background: #1460AA1a;
                        height: fit-content;
                    }
                }

                &.select {
                    background: #1460AA17;
                    height: fit-content;

                    &:hover {
                        cursor: pointer;
                        background: #1460AA23;
                    }

                    &:active {
                        cursor: pointer;
                        background: #fff;
                    }
                }
            }

            & > .select_block {
                margin-left: 50px;

                & > .select {
                    min-width: 230px;
                    display: block !important;

                    & > label { 
                        color: #1460AA;

                        & + div {
                            &:before {
                                border-color: #1460AA9c !important;
                            }
                
                            &:before:hover {
                                border-color: #1460AA86 !important;
                            }
                
                            &:after {
                                border-color: #1460AA !important;
                            }
                        }
                    }

                    & > div {
                        width: 100%;
                        color: #1460AA;
                    }

                    & > p {
                        color: #1460AA;
                        font-size: 14px;
                        padding: 0 10px 0 0;
                    }
                }
            }
        }

        & > .fields {
            display: flex;
            margin-top: 25px;
            margin-bottom: 10px;
            flex-wrap: wrap;
            

            & > .slider_block {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 385px;
                height: 47px;
                
                & .text {
                    color: #707070 !important;
                    text-align: center;
                    margin-bottom: 10px;

                    &.-info {
                        display: flex;
                        font-size: 14px;
                        justify-content: space-between;
                    }
                }
    
                & .slider {

                    button {
                        background: #417BC3 !important;
                    }

                    > div {
                        > div {
                            background: #417BC3;
                        }
                    }
                }
            }

            &.-limit {
                max-width: 890px;
                flex-wrap: wrap;
            }

            &.-price_ee {

                & > div {
                    max-width: 150px;
                }
            }

            &.-script {
                margin-top: 50px;
                & > div {
                    border: 1px solid #cdcdcd;
                    max-width: 350px;
                    width: 350px;
                    border-radius: 4px;
                    padding: 15px;
                }

                .head {
                    color: #417cc3 !important;
                }
            }


            & > div {
                margin-bottom: 30px;
                margin-right: 30px;
                margin-top: 0;
                width: 240px;

                &:last-of-type {
                    align-self: flex-start;
                }
            }

            & > .col-100 {
                width: 450px;
            }

            & label {
                color: #939393 !important;
        
                & + div {
                    &:before {
                        border-color: #1460AA9c !important;
                    }
        
                    &:before:hover {
                        border-color: #1460AA86 !important;
                    }
        
                    &:after {
                        border-color: #1460AA !important;
                    }
                }
            }

            & > div {
                & div {
                    color: #353535 !important;
                }
            }
        
            & input {
                color: #353535 !important;
            }

            & textarea {
                color: #353535 !important;
                width: 350px;
            }

            & > .error {

                & input {
                    color: #e80000 !important;
                }
                
                & > label {
                    color: #e80000 !important;

                    & + div {
                        &:before {
                            border-color: #e80000 !important;
                        }
            
                        &:before:hover {
                            border-color: #e80000 !important;
                        }
            
                        &:after {
                            border-color: #e80000 !important;
                        }
                    }
                }
            }
        }
    }

    & > .supplier_list {
        margin-top: 50px;

        & > .saparetor {
            display: inline-block;
            position: relative;
            color: #353535;
            font-weight: 900;
        }

        & > .content {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            padding: 20px 0;
            
            .supplierWrapper {
                padding: 0 10px !important;
                box-sizing: border-box;
                width: 200px !important;
            }

                .supplier {
                    background: #1460AA69;
                    padding: 0 !important;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    box-shadow: 2.18358px 2.18358px 8.73432px rgba(53, 53, 53, 0.164);
                    overflow: hidden;

                    &:hover {
                        cursor: pointer;

                        & > .actions {
                            display: flex;
                            
                        }
                    }

                    &.-disabled {
                        &:hover {
                            pointer-events: none;
                            cursor: none;

                            & > .actions {
                                display: none;
                                
                            }
                        }
                    }

                    & > .actions {
                        position: absolute;
                        display: none;
                        width: 100%;
                        height: 100%;
                        z-index: 1;

                        & > .background {
                            background: #caf8d5bb;
                            backdrop-filter: blur(2px);
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            opacity: 0.9;
                        }

                        & > .action {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    & > .content {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        & > .block_img {
                            width: 100%;
                            height: 100%;

                            & > .image {
                                width: 100%;
                                height: auto;
                            }
                        }
                    
                        & > .block_text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        }

                        & > .selected {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #caf8d5bd;

                            &::after {
                                content: "Selected";
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                color: #489b5c;
                                padding: 5px 10px;
                                background: #CAF8D5;
                            }
                        }
                    }
                }
        }
    }

    & > .submit {
        display: flex;
        justify-content: center;
        margin-top: 70px;
    }
}

.hide {
    display: none;
}


.autocompleteWrapper {

    .MuiAutocomplete-tag {
        background: #1460aa1c;
        font-size: 16px;
    }

    .MuiChip-deleteIcon {
        color: #145faad5 !important;
    }

    & label {
        color: #1460AA !important;

        & + div {
            &:before {
                border-color: #1460AA9c !important;
            }

            &:before:hover {
                border-color: #1460AA86 !important;
            }

            &:after {
                border-color: #1460AA !important;
            }
        }
    }

    & > div {
        & div {
            color: #1460AA !important;
        }
    }

    & input {
        color: #1460AA !important;
    }

    & textarea {
        color: #1460AA !important;
        width: 350px;
    }

    & > .error {

        & input {
            color: #e80000 !important;
        }
        
        & > label {
            color: #e80000 !important;

            & + div {
                &:before {
                    border-color: #e80000 !important;
                }
    
                &:before:hover {
                    border-color: #e80000 !important;
                }
    
                &:after {
                    border-color: #e80000 !important;
                }
            }
        }
    }
}


.new_offer_seciton > .content > .fields > .-superUser {
    margin-bottom: 20px;
}

.suppliersSliderMini {
    max-width: 20% !important;
    min-width: 500px;
    margin: 0 20px;
}

.suppliersSlider {
    max-width: 70% !important;
    min-width: 1000px;
    margin: 0 20px;
    

    .slick-list {
        height: 130px;
    }
}

// slider arrows

.offers__slickArrow {
    position: absolute;
    top: 33px;
    padding: 2px 10px;
    cursor: pointer;

    &:hover img {
        filter: contrast(70%);
    }

    img {
        transition: .2s ease-in-out;
        width: 12px;
    }
}

.offers__slick-prev {
    left: -33px;
}

.offers__slick-next {
    right: -33px;
}