.operation_section {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 30vh;

    // & > .suppliers_block {

    // }

    & > .switch {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 50px;
        // max-width: 960px;
        position: sticky;
        top: 20px;
        z-index: 1000;

        & > .button {
            width: 150px;
            padding: 8px 0;
            background: #1460AA;
            text-align: center;
            color: white;

            &.-left {
                border-radius: 10px 0 0 10px;
            }

            &.-right {
                border-radius: 0 10px 10px 0;
            }

            &.selected {
                background: #f5A422;
            }

            &.no-selected:hover {
                background: #f5A422;
                cursor: pointer;
            }
        }
    }

    & > .content {

        & > .operations {

            & > .saparetor {
                display: inline-block;
                position: relative;
                color: #353535;
                font-weight: 900;
            }

            & > .fields {
                display: flex;
                justify-content: space-around;
                margin: 50px 0;
                flex-wrap: wrap;


                & > div {
                    margin-bottom: 30px;

                    &:last-of-type {
                        align-self: flex-start;
                    }
                }

                & label {
                    color: #1460AA !important;
            
                    & + div {
                        &:before {
                            border-color: #1460AA9c !important;
                        }
            
                        &:before:hover {
                            border-color: #1460AA86 !important;
                        }
            
                        &:after {
                            border-color: #1460AA !important;
                        }
                    }
                }
            
                & input {
                    color: #1460AA !important;
                }
    
                & textarea {
                    color: #1460AA !important;
                    width: 350px;
                }
            }
        }
    }

    & > .supplier_list {

        & > .saparetor {
            display: inline-block;
            position: relative;
            color: #353535;
            font-weight: 900;
        }

        & > .content {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            padding: 20px 0;
            
            & > ul {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                & > .supplier {
                    min-width: 190px;
                    width: 190px;
                    max-height: 114px;
                    background: #1460AA69;
                    margin: 0 20px;
                    padding: 0 !important;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    overflow: hidden;

                    &:hover {
                        cursor: pointer;

                        & > .actions {
                            display: flex;
                            
                        }
                    }

                    & > .actions {
                        position: absolute;
                        display: none;
                        width: 100%;
                        height: 100%;
                        z-index: 1;

                        & > .background {
                            background: #a1ebff;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            opacity: 0.9;
                        }

                        & > .action {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    & > .content {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        & > .block_img {
                    
                            & > .image {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    
                        & > .text_block {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        }

                        & > .selected {
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            top: 0;
                            right: 0;
                            background: #039fca;
                            text-align: center;
                            border-radius: 0 0 0 30px;
                        }
                    }
                }
            }
        }
    }

    & > .submit {
        display: flex;
        justify-content: center;
        margin-top: 70px;
    }
}

.select_supplier {
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: rgb(138, 138, 138);
}

.show_operation {
    position: relative;

    &::before {
        content: "Attivo";
        position: absolute;
        right: 35px;
        top: -11px;
        font-size: 12px;
        color: #34C253;
        padding: 3px 12px;
        background: #EAFBEE;
        border-radius: 10px;
    }
}

.disabled_operation {
    position: relative;
    pointer-events: none;
    opacity: 0.8;

    &::before {
        position: absolute;
        right: 35px;
        top: -11px;
        content: "Non Attivo";
        font-size: 12px;
        padding: 3px 12px;
        color: #E81D1D;
        background: #FDE8E8;
        border-radius: 10px;
    }
}