.offer_section {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 80px;
    padding-right: 40px;
    padding-bottom: 30vh;

    & > .header {
        display: flex;

        & > .search {
            width: 100%;

            input {
                height: 12px;
            }
            
            label {
                line-height: 15px !important;
            }
        }
    }

    & > .content {
        margin-top: 50px;

        & > .actions {
            display: flex;
            justify-content: center;
            margin-top: 50px;

            & > a > button {
                height: 50px;
                padding: 0px 50px;
                color: white;
                background: #1460AA;
            }
        }

        @media (min-width: 1051px) {
            min-width: 760px;
        }

        & > .offers {
            padding: 5px;
            font-size: 14px;
            width: 710px;

            @media (min-width: 1051px) {
                min-width: 760px;
            }

            & > .header {
                padding: 5px 0;
                display: flex;
                
                & > .item {
                    // border-bottom: 1px solid rgba(0,0,0,0.1);
                    padding: 5px 0px;


                    &.-name {
                        width: 150px;
                        text-overflow: ellipsis;
                    }
                    &.-code {
                        width: 100px;
                    }
                    &.-product {
                        width: 80px;
                    }
                    &.-supplier {
                        width: 100px;
                    }
                    &.-type {
                        width: 100px;
                    }
                    &.-valid {
                        width: 100px;
                    }
                    &.-duration {
                        width: 80px;
                    }
                    &.-sdd {
                        width: 50px;
                    }

                    &.-actions {
                        width: 100px;
                    }

                    @media (max-width: 1050px) {
                        &.-sdd {
                            display: none;
                        }
                    }
                }
            }

            & > .offer {
                padding: 5px 0;
                display: flex;
                color: #4c4c4c;
                border-bottom: 1px solid #b5ebff;
                
                &:nth-child(odd) {
                    background: #f4f8fe;
                }
                
                & > .item {

                    &.-name {
                        width: 145px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        padding-left: 5px;
                    }
                    &.-code {
                        width: 100px;
                    }
                    &.-product {
                        width: 80px;
                    }
                    &.-supplier {
                        width: 100px;
                    }
                    &.-type {
                        width: 100px;
                    }
                    &.-valid {
                        width: 100px;
                    }
                    &.-duration {
                        width: 80px;
                    }
                    &.-sdd {
                        width: 50px;
                    }

                    @media (max-width: 1050px) {
                        &.-sdd {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.row_block {
    & > td {
        padding: 5px 10px;
    }

    &.-valid {
        &:hover {
            cursor: pointer;
        background: #bbceeb;
        }
    }

    &:nth-child(even) {
        background: #f4f8fe;

        &:hover {
            cursor: pointer;
            background: #edf2ff;
        }
    }

    & > th {
        padding: 5px 10px;
    }

    &.-not_valid {
        background: rgba(255,0,0,0.1);

        &:hover {
            cursor: pointer;
            background: rgba(255,0,0,0.08);
        }
    }

    &.-today {
        background: rgba(255, 238, 0, 0.1);

        &:hover {
            cursor: pointer;
            background: rgba(255, 238, 0, 0.08);
        }
    }

    
}

.actions_table {
    width: 150px !important;
    
    & > .action {
        margin: 0 5px;

        &.-edit {
            background: #ceddf1;
            color: white;
        }

        & > span {
            & > span {
                font-size: 18px;
            }
        }
    }
}

.actions_table_user_view {
    width: 70px !important;
    
    & > .action {
        margin: 0 5px;

        &.-edit {
            background: #ceddf1;
            color: #417BC3;
        }

        & > span {
            & > span {
                font-size: 18px;
            }
        }
    }
}

.offer_icon {
}

.offer_supplier {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.logo_supplier_section {
    position: relative;

    .logo_supplier {
        max-height: 35px;
        width: 56px;
        position: relative;
        left: 10px;
        border-radius: 1.5px;
        overflow: hidden;
    
        & > img {
            height: 100%;
            width: 100%;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover + .tooltip_image {
            display: block;
        }
    }

    .tooltip_image {
        display: none;
        width: 250px;
        height: 150px;
        position: absolute;
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
        border-radius: 10px;
        overflow: hidden;
        top: -10px;
        left: 0;
        transform: translate(-50%, -100%);

        & > img {
            width: 100%;
            height: 100%;
        }
    }
}


.tableWrapper {
    border-radius: 24px;
    overflow: hidden;

    thead {
        background: #f4f8fe;
    }

    thead tr th {
        padding: 0 10px !important;
    }
    
    thead tr th:first-child{
        padding: 0 20px !important;
    }

    tbody tr td:first-child {
        padding: 0 20px !important;
    }

    tbody tr:last-child td:first-child {
        border-radius: 0 0 0 24px !important;
    }

    tbody tr:last-child td:last-child {
        border-radius: 0 0 24px 0 !important;
    }
}