.priceTable {

    &__selectionsButtons {
        display: flex;
        flex-wrap: wrap;
    }

    &__selectionBtn {
        background: #9cb9df !important;
        color: white !important;
        margin-right: 40px !important;
        margin-bottom: 10px !important;
        position: relative;
        width: fit-content;
        border-radius: 8px !important;
        padding: 8px 35px !important;

        &:hover {
            background: #598bca !important;
        }
    }

    &__selectionBtn--active {
        @extend .priceTable__selectionBtn;
        background: #588BCA !important;
        pointer-events: none;
    }
} 