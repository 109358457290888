.user_popup_section {
    .fieldsGroup {
        display: flex;
        justify-content: space-between;

        input {
            width: 240px !important;
        }
    }

    .passwordInput {
        width: 100% !important;
    }

    .defaultCheckbox, .defaultCheckbox--checked {
        padding: 10px 5px !important;
    }
}