.users-section {
    margin: 80px 33px 30vh 30px;

    > .header > .search {
        width: 100%;
        margin-bottom: 20px;
    }

    .usersTable {
        .actionsButtons {
            width: 280px !important;
        }
    }
}
