.offer_script {
    width: 350px;
    border-bottom: 1px solid #1460AA;
    height: 200px;
    position: relative;

    & > .actions {
        position: absolute;
        top: -15px;
        right: -15px;

        & > .action {
            margin: 0 5px;
    
            &.-edit {
                background: #1460AA;
                color: white;
            }
    
            & > span {
                & > span {
                    font-size: 18px;
                }
            }
        }
    }

    & > .head {
        position: absolute;
        top: -25px;
        left: 0;
        color: blue;
    }

    & > .content {
        height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}