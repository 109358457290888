.remi_section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.-processing {
        justify-content: center;

        & > .loading {
          }
          .loading-bar {
            display: inline-block;
            width: 4px;
            height: 18px;
            margin: 0 2px;
            border-radius: 4px;
            animation: loading 1s ease-in-out infinite;
          }
          .loading-bar:nth-child(1) {
            background-color: #3498db;
            animation-delay: 0;
          }
          .loading-bar:nth-child(2) {
            background-color: #c0392b;
            animation-delay: 0.09s;
          }
          .loading-bar:nth-child(3) {
            background-color: #f1c40f;
            animation-delay: .18s;
          }
          .loading-bar:nth-child(4) {
            background-color: #27ae60;
            animation-delay: .27s;
          }
          
          @keyframes loading {
            0% {
              transform: scale(1);
            }
            20% {
              transform: scale(1, 2.2);
            }
            40% {
              transform: scale(1);
            }
          }
    }

    & > .help_text {

        & > .head {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        & > .fields {
        }
    }

    // & > .content {
        height: 70vh;
    & > .dropzone {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        & > .upload_file {
            width: 100%;
            display: flex;
            flex: 1;
        }
    }

    & > .actions {

        & > .button {
            background: #f5A422;
            color: white;
            margin: 10px 0 30px 0;

            &.-disabled {
                background: rgb(250, 222, 169);
                pointer-events: none;
            }
        }
    }

    & > .add_manual_drop {
        display: flex;
        flex: 4;
        border-left: 1px solid #efefef;
    }
}

.drop_section {
    position: absolute;
    height: 100%;
    display: flex;
    flex: 8;
    justify-content: center;
    align-items: center;
}

.try_drop_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 8;
}

.droped_file {
    font-size: 24px;
    color: rgb(112, 112, 112);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}