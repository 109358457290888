.load_section {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    opacity: 0;

    animation: loadingAnim 0.9s forwards;

    & > .background {
        position: absolute;
        background: #82daff;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        color: #b3b3b3;
        font-weight: 300;
        font-size: 24px;
        font-style: italic;
    }

}


@keyframes loadingAnim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}