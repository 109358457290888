.comperatore {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 80px;
    padding-right: 40px;

    &__selectionsButtons {
        display: flex;
        flex-wrap: wrap;
    }

    &__addBtaBtn {
        background: #588BCA !important;
        color: white !important;
        margin-right: 40px !important;
        margin-bottom: 10px !important;
        position: relative;
        width: fit-content;
        border-radius: 8px !important;
        padding: 8px 35px !important; 

        &:hover {
            background: #236cb4 !important;
        }
    }

    &__selectionBtn {
        background: #9cb9df !important;
        color: white !important;
        margin-right: 40px !important;
        margin-bottom: 10px !important;
        position: relative;
        width: fit-content;
        border-radius: 8px !important;
        padding: 8px 35px !important;

        &:hover {
            background: #598bca !important;
        }
    }

    &__addBtn {
        background: #ff9c28 !important;
        color: white !important;
        position: relative;
        width: fit-content;
    }

    &__selectionBtn--active {
        @extend .comperatore__selectionBtn;
        background: #588BCA !important;
        pointer-events: none;
    }

    // imposte and sintetica

    &__switcherWrapper {
        display: flex;
        margin-top: 50px;
        align-items: center !important;
        justify-content: center;

        .comperatore__selectionBtn {
            margin:  0 !important;
        }
    }

    &__switcher {
        margin:  0 auto;
        border: 1px solid #dbe0e7;
        border-radius: 20px;
        display: flex;
        width: fit-content;
        padding: 5px;
    }

    &__switcherTab {
        border-radius: 29px;
        cursor: pointer;
        padding: 7px 12px;
        border: 1px solid transparent;
        color: #868686;
        transition: .3s ease-in-out;

        &:first-child {
            margin-right: 15px;
        }

        &:hover {
            border: 1px solid #5c94da;
            background: #5c95da1f;
        }
    }

    &__switcherTab--active {
        @extend .comperatore__switcherTab;
        background: #5c94da;
        pointer-events: none;
        color: #fff;
    }

    // headings comperatore 

    &__section {
        margin-top: 40px;
        margin-bottom: 100px;
        // width: fit-content;
    }

    &__title {
        font-size: 18px;
        color: #353535;
        letter-spacing: 0.02em;
        padding: 7px 0;
        margin-top: 24px;
    }

    &__titleBold {
        @extend .comperatore__title;
        font-weight: 700;
        font-size: 22px;
    }

    &__subtitle {
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #417BC3;
        font-weight: 600;  
        padding: 7px 0;  
        margin-top: 15px;
    }

    // inouts

    &__gasInputGroup {
        display: flex;
        flex-direction: column;
    }

    &__defaultInput {
        width: 200px;
        margin-top: 10px !important;
        margin-right: 40px !important;

        // helper text
        p {
            color: #939393;
        }
    }

    &__defaultInput--long {
        width: 350px;
        margin-top: 10px !important;
        margin-right: 40px !important;
        
        // helper text
        p {
            color: #939393;
        }
    }
    
    &__selectInput {
        width: 250px;
        margin: 20px 45px 20px 0 !important;
        // border: 1px solid red !important;
    }
    
    &__input--long {
        width: 250px;
        margin: 20px 45px 20px 0 !important;
        // border: 1px solid blue !important;
    }

// separator 

    &__separator {
        display: block; 
        height: 1px;
        border: 0; 
        border-top: 1px solid #EDEDED;
        padding: 0;
        margin: 20px 0;
        width: 100%;
    }

    // ok calcel butns 

    &__okCancelButtonsWrapper {
        margin: 0 auto;
        margin-top: 50px;
        width: fit-content;

        button {
            padding: 8px 25px !important;
            box-shadow: none;
            border-radius: 2px !important;
        }
    }

    &__updateBtn {
        margin-bottom: 50px;
        button {
            padding: 8px 25px !important;
            box-shadow: none;
            border-radius: 2px !important;
        }
    }

    // gas

    &__twoColumns {
        display: flex;
        grid-gap: 100px;
    }

    &__gasTableWrapper {
        width: fit-content;
        position: relative;

        /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
    }

    // fake table (svg) with in puts


    &__tableInput {
        width: 160px;
        position: absolute;
        border-radius: 6px;
        border: 1px solid #dde7f3;
        background: #fff;
        padding: 8px 15px;
        width: 50px;
        color: #494949;
        outline-color: #bed4ee;
    }

    &__tableInputLeft {
        position: absolute;
        top: 237px;
        left: 482px;
    }

    &__tableInputRight {
        position: absolute;
        top: 237px;
        left: 697px;    
    }

    &__tableInputRightBottom {
        position: absolute;
        top: 290px;
        left: 697px; 
    }

    // for tables 

    &__tableWrapper {
        width: 1000px;
        margin-top: 20px;
    }

    // action icon 

    &__actionButtonsWrapper {
        display: flex;
        width: fit-content;

        button {
            border-radius: 50% !important;
            width: 40px  !important;
            height: 40px !important;
            padding: 11px !important;
            width: fit-content !important;
            min-width: 0 !important;
        }
    }

    // &__actionBtn {
    //     background: #5c95d9;
    //     color: #fff;
    //     border-radius: 50%;
    //     height: 35px;
    //     width: 35px;
    //     text-align: center;
    //     padding: 7px;
    //     box-sizing: border-box;
    //     transition: .2s ease-in-out;
    //     margin-right: 7px;

    //     &:hover {
    //         background: #5c94d9d7;
    //     }
    // }

    // &__actionBtn--removeBtn {
    //     @extend .comperatore__actionBtn;
    //     background: #da1111ec;

    //     &:hover {
    //         background: #d31010d5;
    //     }
    // }

    // bta

    &__btaWrapper {
        background: #fbfcff;
        padding: 30px;
        width: 70%;
        display: flex;
        color: #353535;
        margin-top: 20px;
        position: relative;
    }

    &__btaTitle {
        font-size: 16px;
        margin: 5px 0 10px 0;
    }

    &__btaText {
        font-size: 15px;
    }

    &__btaLabel {
        font-size: 14px;
        color: #939393;
    }

    &__btaActionButtons {
        position: absolute;
        top: -20px;
        right: -20px;

        button {
            border-radius: 50% !important;
            width: 40px  !important;
            height: 40px !important;
            padding: 11px !important;
            // padding: 0px !important;
            width: fit-content !important;
            min-width: 0 !important;
        }
    }

    // tables 

    &__tableHead {
        background: rgba(112, 112, 112, 0.068);
        // background: #70a7e11f;
    }
}

.inputsWrapper {
    display: flex;
    grid-gap: 25px;
}

// .MuiFormControl-root {
//     margin-bottom: 0px!important;
//     margin-top: 0px !important;
// }

// accents 
.accentText {
    font-weight: 600;
    color: #fff;
}

.accentRow--blue {
    background: #1361aa;
    // background: #417cc3;
}

.accentRow--orange {
    background: #f5a422;
}

.accentRow--lightAccent {
    background: #6d9eda;
}

.borderTop {
    border-top: 5px solid #fff;
}

// bottom messages 
.bottomMessagesSection {
    margin-top: 40px;
    display: flex;
}

.bottomMessages {
    display: flex;
    flex-direction: column;
    
    &__message {
        background: #f7f7f7;
        padding: 15px;
        position: relative;
        margin-bottom: 30px;
        border-radius: 3px;
        // min-width: 100px;
        width: fit-content;
    }

    &__actions {
        position: absolute;
        display: flex;
        top: -20px;
        right: -20px;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.text_center {
    text-align: center;
}

.hide {
    display: none !important;
}

.offerExpandBtn {
    display: flex;
    align-items: center;
    margin-left: 70px;
}

.opacityNone {
    opacity: 0;
    pointer-events: none;
}

