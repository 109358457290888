.script_item {
    // margin: 20px 40px;
    position: relative;
    max-width: 500px;

    & > .actions {
        position: absolute;
        top: -35px;
        right: -35px;
    }

    & > .content {

        &.-edit {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}


.messageWrapper {
    // border: 2px solid orange;
    position: relative;
    width: fit-content;
    
}

.orderArrowsWrapper {
    //  border: 2px solid red;
    width: 50px;
    // height: 20px;
    position: absolute;
    top: -10px;
    left: 70px;
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
}

.orderArrowWrapper {
    // border: 2px solid green;

    padding: 4px 7px;
    cursor: pointer;
// }
cursor: pointer;

&:hover .triangle-left {
    border-right: 10px solid #b1c7e6;
}

&:hover .triangle-right {
    border-left: 10px solid #b1c7e6;
}
}

.orderArrows {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
}

.triangle-left {
    @extend .orderArrows;
    border-right: 10px solid #C3D3E9;
}

.triangle-right {
    @extend .orderArrows;
    border-left: 10px solid #C3D3E9;
}
