.body_upload {
    overflow: auto;
    & > #perview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > div {
            // width: calc(100% / 1.2);
            // height: calc(100% / 1.2);
            max-height: 250px;
            display: inline-block;
            & > img {
                // width: calc(100% / 1.2);
                // height: calc(100% / 1.2);
                max-height: 250px;
            }
        }
        & > .upload {
            position: relative;
            padding: 10px 15px;
            margin-top: 20px;
            font-weight: 700;
            background: #1460AA;
            color: white;
            border-radius: 4px;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
    
            & > .select_button {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.testimage {
    width: 250px;
    height: 150px;
    display: block;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}