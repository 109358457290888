.number_popup_section {

    & > .fields {
        margin-bottom: 15px;

        &.-phone {
            margin-top: 15px;
        }

        & > .field {
            display: flex;
            flex-direction: column;
            width: 100%;

            & label {
                color: #939393 !important;
        
                & + div {
                    &:before {
                        border-color: #939393 !important;
                    }
        
                    &:before:hover {
                        border-color: #939393 !important;
                    }
        
                    &:after {
                        border-color: #939393 !important;
                    }
                }
            }
        
            & input {
                color: #353535 !important;
            }

            & textarea {
                color: #353535 !important;
            }
        }
    }

    & > .quill {

        & > .ql-container {
            height: 250px;
        }
    }
}