.supplier_section {
    margin: 80px 33px;

    & > .content {
        display: flex;
        padding-top: 50px;
        flex-wrap: wrap;

        & > .supplier {
            position: relative;
            width: 250px;
            height: 150px;
            margin-left: 60px;
            margin-bottom: 60px;
            background: #1460AA69;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            box-shadow: 3px 3px 12px rgba(53, 53, 53, 0.08);
            text-transform: uppercase;
            color:#353535;

            &:hover {
                // box-shadow: 0 3px 23px 3px rgba(0,0,0,0.1);
                // top: -5px;
                // left: -2px;

                & > .actions {

                    & > .action {
                        visibility: visible;
    
                        &.edit {
                            background: #9bc1f05e;
                        }
    
                        &.remove {
                            background: #f9958a6b;
                        }
                    }
                }
            }

            & > .actions {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;

                &:hover {
                    background: rgba(255, 255, 255, 0.9);
                }

                & > .action {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    visibility: hidden;
    
                    &:hover {
                        cursor: pointer;
    
                        &.edit {
                            background: #9bc1f0c7;
                        }
    
                        &.remove {
                            background: #f9958ac9;
                        }
                    }
                }
            }

            & > .content {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;

                & > .block_img {
                    width: 100%;
                    height: 100%;
                    
                    & > .image {
                        width: 100%;
                        height: 100%;
                    }
                }

                & > .block_text {
                    font-size: 24px;
                }
            }
        }
    }

}