// .new_supplier_section {
//     display: flex;
//     flex-direction: column;
//     padding-left: 40px;
//     position: relative;
//     padding-top: 40px;
//     padding-right: 40px;
//     padding-bottom: 30vh;

//     & > .main {
//         display: flex;

//         & > .fields {
//             display: flex;
//             flex-direction: column;
//             width: 100%;

//             & label {
//                 color: #1460AA !important;
        
//                 & + div {
//                     &:before {
//                         border-color: #1460AA9c !important;
//                     }
        
//                     &:before:hover {
//                         border-color: #1460AA86 !important;
//                     }
        
//                     &:after {
//                         border-color: #1460AA !important;
//                     }
//                 }
//             }
        
//             & input {
//                 color: #1460AA !important;
//             }

//             & textarea {
//                 color: #1460AA !important;
//             }
//         }

//         & > .upload_image {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: flex-start;

//             & > .image_block {
//                 width: 250px;
//                 height: 150px;
//                 background: #ECF1F8;
//                 overflow: hidden;
//                 border-radius: 4px;
//                 display: flex;
//                 justify-content: center;
//                 flex-direction: column;
//                 align-items: center;
//                 position: relative;
//                 // box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

//                 & > .image {
//                     width: 100%;
//                     height: 100%;

//                     &:hover {
//                         cursor: pointer;
//                         opacity: 0.8;
//                     }
//                 }

//                 & > .text {
//                     color: #1460AA;
//                     font-weight: 900;
//                     width: 100%;
//                     height: 100%;
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     display: flex;
//                     justify-content: center;
//                     flex-direction: column;
//                     align-items: center;

//                     &:hover {
//                         cursor: pointer;
//                         opacity: 0.8;
//                     }
//                 }
//             }
//         }
//     }

//     & .filter_section {

//         margin-top: 100px;

//         & > .head { 
//             margin-bottom: 30px;
//         }

//         & > .content {

//             & > .saparetor {
//                 display: inline-block;
//                 position: relative;
//                 color: #707070;
//             }

//             & > .filters {
//                 display: flex;
//                 align-items: flex-end;
//                 flex-wrap: wrap;
//                 position: relative;
//                 width: calc(100% - 120px);
//                 margin-top: 20px;

//                 &.-m {
//                     // top: -55px;
//                 }

//                 &.-not {
//                     width: calc(100% - 140px);
//                     // top: -65px;
//                     // left: 140px;
//                 }

//                 & > .item {
//                     padding: 10px 0;
//                     width: 170px;
//                     height: fit-content;
//                     font-weight: 600;
//                     text-align: center;
//                     color: #1460AA;
//                     border-bottom: 1px solid #1460AA;
//                     margin-bottom: 30px;
//                     margin-right: 50px;

//                     &.no_select {
//                         &:hover {
//                             cursor: pointer;
//                             background: #1460AA0e;
//                             height: fit-content;
//                         }
    
//                         &:active {
//                             cursor: pointer;
//                             background: #1460AA1a;
//                             height: fit-content;
//                         }
//                     }

//                     &.select {
//                         background: #1460AA17;
//                         height: fit-content;

//                         &:hover {
//                             cursor: pointer;
//                             background: #1460AA23;
//                         }
    
//                         &:active {
//                             cursor: pointer;
//                             background: #fff;
//                         }
//                     }
//                 }

//                 & > .select_block {
//                     margin-right: 30px;

//                     & > .select {
//                         min-width: 250px;
//                         display: block !important;

//                         & > label { 
//                             color: #1460AA;

//                             & + div {
//                                 &:before {
//                                     border-color: #1460AA9c !important;
//                                 }
                    
//                                 &:before:hover {
//                                     border-color: #1460AA86 !important;
//                                 }
                    
//                                 &:after {
//                                     border-color: #1460AA !important;
//                                 }
//                             }
//                         }

//                         & > div {
//                             width: 100%;
//                             color: #1460AA;
//                         }

//                         & > p {
//                             color: #1460AA;
//                             font-size: 14px;
//                             padding: 0 10px 0 0;
//                         }
//                     }
//                 }
//             }
//         }

//         & > .action_section {
//             display: flex;
//             justify-content: center;
//             margin-top: 70px;
//         }
//     }
// }

// .coperturaNazional {
//     margin-top: 30px;
// }

.new_supplier_section {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 30vh;

    & > .main {
        display: flex;

        & > .mainInfo {
            width: 500px !important;
            margin-right: 100px;

            .head {
                margin-bottom: 25px;
            }
        }

        & > .fields {
            display: flex;
            flex-direction: column;
            width: 100%;

            & label {
                color: #1460AA !important;
        
                & + div {
                    &:before {
                        border-color: #1460AA9c !important;
                    }
        
                    &:before:hover {
                        border-color: #1460AA86 !important;
                    }
        
                    &:after {
                        border-color: #1460AA !important;
                    }
                }
            }
        
            & input {
                color: #1460AA !important;
            }

            & textarea {
                color: #1460AA !important;
            }
        }

        & > .upload_image {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            

            & > .image_block {
                width: 250px;
                height: 150px;
                background: #ECF1F8;
                overflow: hidden;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                position: relative;
                // box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

                & > .image {
                    width: 100%;
                    height: 100%;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }

                & > .text {
                    color: #1460AA;
                    font-weight: 900;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    & .filter_section {

        margin-top: 100px;

        & > .head { 
            margin-bottom: 30px;
        }

        & > .content {

            & > .saparetor {
                display: inline-block;
                position: relative;
                color: #707070;
                margin-top: 5px;
            }

            & > .filters {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                position: relative;
                width: calc(100% - 120px);
                margin-top: 20px;

                &.-m {
                    // top: -55px;
                }

                &.-not {
                    width: calc(100% - 140px);
                    // top: -65px;
                    // left: 140px;
                }

                & > .item {
                    padding: 10px 0;
                    width: 170px;
                    height: fit-content;
                    font-weight: 600;
                    text-align: center;
                    color: #1460AA;
                    border-bottom: 1px solid #1460AA;
                    margin-bottom: 30px;
                    margin-right: 50px;

                    &.no_select {
                        &:hover {
                            cursor: pointer;
                            background: #1460AA0e;
                            height: fit-content;
                        }
    
                        &:active {
                            cursor: pointer;
                            background: #1460AA1a;
                            height: fit-content;
                        }
                    }

                    &.select {
                        background: #1460AA17;
                        height: fit-content;

                        &:hover {
                            cursor: pointer;
                            background: #1460AA23;
                        }
    
                        &:active {
                            cursor: pointer;
                            background: #fff;
                        }
                    }
                }

                & > .select_block {
                    margin-right: 30px;

                    & > .select {
                        min-width: 250px;
                        display: block !important;

                        & > label { 
                            color: #1460AA;

                            & + div {
                                &:before {
                                    border-color: #1460AA9c !important;
                                }
                    
                                &:before:hover {
                                    border-color: #1460AA86 !important;
                                }
                    
                                &:after {
                                    border-color: #1460AA !important;
                                }
                            }
                        }

                        & > div {
                            width: 100%;
                            color: #1460AA;
                        }

                        & > p {
                            color: #1460AA;
                            font-size: 14px;
                            padding: 0 10px 0 0;
                        }
                    }
                }
            }
        }

        & > .action_section {
            display: flex;
            justify-content: center;
            margin-top: 70px;
        }
    }
}

.coperturaNazional {
    margin-top: 30px;
}