.home_addButton {
    position: fixed !important;
    width: 55px !important;
    min-width: 55px !important;
    height: 55px !important;
    top: 20px;
    right: 20px;
    z-index: 1;
    background: #f5A422 !important; 
    border-radius: 200px !important;
    color: white !important;
}

.home_content {
    margin-top: 80px;
    position: relative;

    .add_button {
        margin-right: 32px;
    }
}