.manageFields {

    &__mainActions {
        margin: 30px 0 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__inputsWrapper {
        width: 65%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }

    &__updateBtn {
        color: #ffffff !important;
        background: #417BC3 !important;
    }

    
}