.scripts_section {
    // padding-top: 30px;
    margin-top: 50px;


    & > .content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row !important;
    }
}