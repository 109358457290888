.header_pausa {
    display: flex;
    flex-direction: column;
}

.pause_button_active {
    width: 322px;
    background: #FF9C29;
    padding: 10px 5px;
    text-align: center;
    border-radius: 10px;
    color: white;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.pause_button {
    width: 322px;
    background: #598BCA;
    padding: 10px 5px;
    text-align: center;
    border-radius: 10px;
    color: white;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.statistic {
    margin-top: 20px;
    width: 322px;
    padding: 10px 5px;
    border: 1px solid #598BCA;
    position: relative;

    & >.statistic_header {
        position: absolute;
        color:#598BCA;
        font-weight: 600;
        top: -10px;
        background: white;
        padding: 0px 10px;
        left: 70px;
    }
}

.statistic_info {
    width: 322px;
    display: flex;
    justify-content: space-around;
    color:#598BCA;
    font-weight: 300;
}