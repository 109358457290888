/* for slider */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
    background: #417bc3;
    margin: 0;
    padding: 0;
    font-family: "Roboto slab", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
}

.anim_content {
    opacity: 0;
    animation: anim 0.5s forwards;
}

.anim_content2 {
    opacity: 0;
    animation: anim2 0.5s forwards;
}

@keyframes anim {
    from {
        opacity: 0;
    } 

    to {
        opacity: 1;
    }
}

@keyframes anim2 {
    from {
        opacity: 0;
    } 

    to {
        opacity: 1;
    }
}

.visible-item {
    overflow: visible !important;
}


//  NEW STYLE

.add_button {
    z-index: 1;
    padding: 12px 18px !important;
    color: white !important;
    background: linear-gradient(277.72deg, #FF9C29 28.61%, #E9A744 102.98%);
    border-radius: 29px !important;
    text-transform: uppercase !important;
    margin-left: 32px !important;
    white-space: nowrap !important;
    font-size: 15px !important;
    font-weight: 300 !important;

    .add_button--plusSign {
        font-size: 22px !important;
        line-height: 20px;
        margin-top: -5px;
        margin-right: 7px;
        font-weight: 200 !important;
    }
}

.actionBtn {
    box-shadow: none !important;
    cursor: pointer;
    border-radius: 50% !important;

    &--edit {
        background: #d1dff0 !important;
        margin-right: 10px !important;
    }

    &--remove {
        background: #f4d3d1 !important;
    }

    .editIcon {
        color: #417BC3;
        font-size: 18px;
    }

    .removeIcon {
        color: #e15140;
        font-size: 18px;
    }
}

.actionBtn.actionBtn--remove {
    background: #f4d3d1 !important;
    cursor: pointer;
}

.defaultButton {
    // background: #417BC3 !important;
    background: #8090a396 !important;

    &:hover {
        background: #296bbb !important;
    }
}   

.mediumInput {
    width: 300px !important;
    // input {
    //     // border: 1px solid red !important;
    //     height: 15px;
    // }

    // label {
    //     line-height: 16px;
    // }
}

.defaultCheckbox {
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    height: 20px !important;
    width: 250px;
    padding: 16px 14px !important;
    color: #707070 !important;
    margin: 0 30px 30px 0 !important;
    // transition: .2s ease-in-out;

    span:last-child {
        margin-left: 10px;
        color: #5f5f5f !important;
    }

    &:hover {
        border: 1px solid #417cc3a9;
    }
}

.defaultCheckbox--checked {
    @extend .defaultCheckbox;
    background: #417cc318;
    border: 1px solid #417cc3a9;

    span:last-child {
        color: #417cc3 !important;
    }
}

.smallInput {
    width: 230px !important;
    input {
        // border: 1px solid red !important;
        height: 15px;
    }

    label {
        line-height: 16px;
    }
}


//  --------------

.notification {
    & > div {
         & > div {
            text-align: center;
            width: 100%;
         }
    }
}

.how_to {
    height: 80vh;
}

.spinner {
    width: 60px;
    height: 60px;
  
    position: relative;
    margin: 25px auto;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f5A422;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }

.how_to_button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    background: #1460AA !important;
    width: 50px !important;
    min-width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1000;
    

    & > span {
        color: white;

        & > span {
            font-size: 24px;
        }
    }
}

.address-select {
    width: 100%;
    
    & > div {
        width: 100%;
    }
    
    & label {
        color: #1460AA !important;

        & + div {
            &:before {
                border-color: #1460AA9c !important;
            }

            &:before:hover {
                border-color: #1460AA86 !important;
            }

            &:after {
                border-color: #1460AA !important;
            }
        }
    }

    & > div {
        & div {
            color: #1460AA !important;
        }
    }
}

.disable_screen {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
}

ul {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

// popups with text editor 
.ql-toolbar.ql-snow {
    border: none !important;
    background: #f6faff !important;
    border-radius: 48px !important;
    padding: 14px 24px !important;

    .ql-formats:first-child  {
        margin-right: 10px !important;

        .ql-header.ql-picker {
            border: 1px solid #CFDCED !important;
            border-radius: 4px !important;
            padding: 3px 0 !important;
            height: auto !important;
            box-sizing: border-box !important;
        }

        .ql-header.ql-picker.ql-expanded > .ql-picker-label {
            border: 1px solid transparent;
        }


        // color
        .ql-color.ql-picker.ql-color-picker {
            border: 1px solid #CFDCED !important;
            border-radius: 4px !important;
            padding: 3px 0 !important;
            height: auto !important;
            margin-left: 10px;
            line-height: 14px;
            box-sizing: border-box !important;
        }


        .ql-color.ql-picker.ql-color-picker.ql-expanded > .ql-picker-label {
            border: 1px solid transparent;
        }
    }

}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc !important;
    margin-top: 8px !important;
}

// for full width inout
.input--long {
    width: 100% !important;
}

// .input--error {
//     border-radius: 4px;
//     box-sizing: border-box;
//     outline: 2px solid rgba(243, 9, 9, 0.171);
//     input {
//         border-radius: 4px;
//         // border: 1px solid rgb(250, 87, 87) !important;
//     }
// }

#red_text {
    color: red !important;
}