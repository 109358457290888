.popup_offer_info {
    height: 100%;

        & > .list_offer {
            display: flex;
            flex-direction: column; 

            & > .head {
                display: flex;
                background: #1460AA25;

                & > .field {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 5px;
                    // border-left: 1px solid rgba(0,0,0, 0.1);
                    color: #8a8a8a;
                    font-weight: 600;
                    font-size: 14px;

                    &.-name {
                        min-width: 250px;
                        max-width: 250px;
                    }

                    &.-valid {
                        min-width: 150px;
                        max-width: 150px;
                    }

                    &.-payment {
                        min-width: 300px;
                        max-width: 300px;
                    }

                    &.-supplier {
                        min-width: 150px;
                        max-width: 150px;
                    }
                }
            }

            & > .offer {
                position: relative;
                // border-top: 1px solid rgba(0,0,0, 0.1);
                // border-bottom: 1px solid rgba(0,0,0, 0.1);
                // border-right: 1px solid rgba(0,0,0, 0.1);\

                &.-not_valid {
                    background: rgba(253, 140, 140, 0.247) !important;

                    &:nth-child(odd) {
                        background: rgba(253, 140, 140, 0.144) !important;
                    }

                    // &:nth-child(even) {
                    //     background: rgba(253, 140, 140, 0.144) !important;
                    // }
                }

                &:nth-child(odd) {
                    background: #d7f6ff;

                    & > .body {
                        background: #e6faff;
                    }

                    &.-not_valid {
                        & > .body {
                            background: rgba(253, 140, 140, 0.247) !important;
                        }
                    }
                }

                & > .head {
                    display: flex;

                    &:hover {
                        cursor: pointer;
                        // opacity: 0.7;
                    }

                    & > .field {
                        height: 40px;
                        line-height: 40px;
                        padding-left: 5px;
                        // border-left: 1px solid rgba(0,0,0, 0.1);
                        color: grey;
                        font-weight: 700;
                        font-size: 14px;
    
                        &.-name {
                            min-width: 250px;
                            max-width: 250px;
                        }
    
                        &.-valid {
                            min-width: 150px;
                            max-width: 150px;
                        }
    
                        &.-payment {
                            min-width: 300px;
                            max-width: 300px;
                        }
    
                        &.-supplier {
                            min-width: 150px;
                            max-width: 210px;

                            & > .offer_supplier {
                                & > .sup_name {
                                    width: 200px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                & > .body {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid #efefef;
                    padding: 0 5px;
                    color: grey;
                    font-weight: 700;

                    & > .section {
                        display: flex;
                        justify-content: space-around;
                        border-bottom: 1px solid #efefef;


                        & > .field {
                            height: 40px;
                            line-height: 40px;
                            font-weight: 500;

                            & > .text {
                                color: #797979;
                                font-weight: 600;
                                font-size: 14px;
                            }

                        }

                        & > .link_offer {
                            display: flex;
                            justify-content: center;
                            padding: 5px 0;
                            
                            & > a > button {
                                background: #1460AA;
                                color: white;
                            }
                        }

                        &.-script {
                            flex-direction: column;
                            border-bottom: none;
                            overflow: auto;
                            display: flex;

                            & > .field {
                                border-bottom: 1px solid #efefef;
                                height: auto;

                                & > .header {
                                    margin-top: 0;
                                    margin-bottom: 0px;
                                    text-align: center;
                                    color: #6e6e6e;
                                    font-size: 16px;
                                }

                                & > .text_script {
                                    padding: 10px 50px;
                                    color: grey;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }

                & > .radio {
                    position: absolute;
                    top: -3px;
                    right: -50px;
                    color: #46a7ff;
                }
            }
        }
}