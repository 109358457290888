.autocomplete__wrapper {
    position: relative;
}

.autocomplete__input {
    position: relative;
}

.autocomplete__btn { 
    background: #2c82d3b4 !important;
    color: white !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    position: relative;
    font-size: 12px !important;
    // padding: 5px 7px !important;

    &:hover {
        background: #1460AA !important;
    }
}

.autocompleteAdress {
    margin-top: 20px;

}

.addressLoader {
    width: fit-content;
    position: absolute;
    bottom: 7px;
    right: 30px;
}

.addressInfoWrapper {
    color: #6d5ff4 !important;
    padding: 15px 0;
    border-bottom: 1px solid #f5f8f9;
}

// .errorMessage {
//     color: #e80000 !important;
//     font-size: 14px;
// }

.telcoOperators {

    // &__loadingOverlay {
    //     // border: 2px solid blue;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(255, 255, 255, 0.692);
    //     z-index: 100;
    //     backdrop-filter: blur(10px);

    //     .MuiCircularProgress-root {
    //         width: 200px;
    //         margin:  0 auto;
    //         margin-left: 40%;
    //         margin-top: 30%;
    //     }
    // }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    &__operator {
        padding: 15px;
        border-radius: 5px;
        margin: 10px 20px 10px 0;
        border: 1px solid #cad9e9;

        img {
            width: 110px;
        }

        &:hover {
            background: #eff4f9;
        }
    }

    &__operatorName{
        font-weight: 600;
        margin-bottom: 5px;
    }
}