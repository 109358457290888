.opeation_script {
    width: 350px;
    border: 1px solid #D8D8D8;
    height: 200px;
    position: relative;
    margin-top: 50px;
    margin-right: 60px !important;
    padding: 20px;
    border-radius: 4px;

    & > .actions {
        position: absolute;
        top: -25px;
        right: -25px;

        & > .action {
            margin: 0 5px;
    
            &.-edit {
                background: #D1DFF1;
                color: white;
                box-shadow: none;
            }
    
            & > span {
                & > span {
                    font-size: 18px;
                }
            }
        }
    }

    & > .head {
        position: absolute;
        top: -25px;
        left: 0;
        color: #1460AA;
    }

    & > .content {
        height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}