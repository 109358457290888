.numbers_section {
    margin: 80px 33px 30vh 30px;

    & > .content {
        display: flex;
        flex-wrap: wrap;
        align-items:flex-start;
        align-content:flex-start;
    }
}

.numbersTableWrapper {
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
    // margin-left: 20px;
    box-sizing: border-box;

    .numbersTable {
        width: 100%;
    }

    thead {
        background: #f4f8fe;
    }

    thead tr th {
        color: #7B7B7B;
        border: none;
    }
    
    thead tr th:first-child{
        padding: 15px 30px !important;
    }

    thead tr th:last-child{
        // border: 2px solid red;
        text-align: left;
        padding: 15px 0 !important;
    }

    // tbody tr:nth-child(even) {
        // background: #eee !important;
    // }

    tbody tr {
        border-bottom: none !important;
    }

    tbody td {
        padding: 10px 0;
        // border: 2px solid red;
        border: none !important;
    }


    tbody tr td:first-child {
        padding: 30px !important;
    }

    tbody tr:last-child td:first-child {
        border-radius: 0 0 0 24px !important;
    }

    tbody tr:last-child td:last-child {
        border-radius: 0 0 24px 0 !important;
        border: 2px solid red;
    }
}



.numbersTableWrapper_search {
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
    // margin-left: 20px;
    box-sizing: border-box;

    .numbersTable {
        width: 100%;
    }

    thead {
        background: #f4f8fe;
    }

    thead tr th {
        color: #7B7B7B;
        border: none;
    }
    
    thead tr th:first-child{
        // padding: 15px 30px !important;
    }

    thead tr th:last-child{
        // border: 2px solid red;
        text-align: left;
        // padding: 15px 0 !important;
    }

    // tbody tr:nth-child(even) {
        // background: #eee !important;
    // }

    tbody tr {
        border-bottom: none !important;
    }

    tbody td {
        // padding: 10px 0;
        // border: 2px solid red;
        border: none !important;
    }


    tbody tr td:first-child {
        padding: 0 10px !important;
    }

    tbody tr:last-child td:first-child {
        border-radius: 0 0 0 24px !important;
    }

    tbody tr:last-child td:last-child {
        border-radius: 0 0 24px 0 !important;
        border: 2px solid red;
    }
}

.expandTableBtnWrapper {
    margin-left: 40px !important;
    height: 40px;
    width: 40px;
}

.actionsButtons {
    width: 180px !important;
}

.orderArrows {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}

.triangle-down {
    @extend .orderArrows;
    border-top: 10px solid #417BC3;
}

.triangle-top {
    @extend .orderArrows;
    border-bottom: 10px solid #417BC3;
}

.oddRow {
    background: #F4F8FE;
    padding: 20px;
}

.evenRow {
    background: #fbfcff;
    padding: 20px;
}

.collapse_text {
    padding: 15px;
}

.collapse_number {
    padding: 15px;
}